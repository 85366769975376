import {
    IModuleMetricDefinition,
    ThresholdType,
    Xtd
} from "../../dashboard/api/dashboardModels";
import {DataType} from "util/dataFormatUtil";
import {PageCode} from "features/configuration/api/uiConfigurationModels";

export enum BusinessUnitType {
    Unknown = "Unknown",
    Group = "Group",
    Location = "Location"
}

export enum MetricType {
    Location = "Location",
    Transaction = "Transaction"
}

// Page Codes available to Freemium users for different products
export const coachFreemiumPageCodes = [
    PageCode.NetSales,
    PageCode.Tracking,
    PageCode.Delivery,
    PageCode.Recovery
];

export const detectFreemiumPageCodes = [PageCode.Loss];

export const deliveryFreemiumPageCodes = [
    PageCode.DeliveryLoss,
    PageCode.DeliveryProviderPaymentData
];

export interface IBusinessUnit {
    businessUnitId: string;
    businessUnitPk: number;
    businessUnitName: string;
    businessUnitDescription: string;
    businessUnitType: BusinessUnitType;
    clientConcept_PK: number;
    parentBusinessUnitName: string;
    parentBusinessUnitId?: string;
}

export interface IClientConcept {
    clientConcept_PK: number;
    concept: Concept;
    conceptName: string;
    conceptUrlName: string;
    calendarId: number;
    rootGroupId: string;
    periodStartDates: IPeriodStartDate[];
}

export interface IClientConceptPage {
    clientConcept_PK: number;
    page_PK: number;
    pageCode: PageCode;
    pageName: string;
    pageRoleAccess: PageRoleAccess;
    defaultXtd: Xtd;
    isNavigable: boolean;
    product: Product;
}

export interface IClientConceptMetricDefinition {
    clientConcept_PK: number;
    metricCode: string;
    metricCategory: string;
    metricDefinitionId: number;
    metricType: MetricType;
    alias: string;
    dataType: DataType;
    decimalPlaces: number;
    description: string;
    isMetricUsed: boolean;
}

export interface IUserSettings {
    defaultBusinessUnitId: string;
    isLocation: boolean;
}

export interface IUserInfo {
    isAdmin: boolean;
    isRecoveryAdmin: boolean;
    isHomeOfficeUser: boolean;
    isDelagetUser: boolean;
    isFreemium: boolean;
    userName: string;
    emailAddress: string;
    lastAction: string;
    clientUserID: string;
    clientId: string;
    clientCode: string;
}

export interface IPeriodStartDate {
    calDt: string;
    perOfYrNbr: number;
}

export interface IDomainSettings {
    legacySiteBaseUrl: string;
    websiteBaseUrl: string;
}

export interface IMetricConfigurationParams {
    saveButtonClicked: boolean;
}

// These values should match ConceptId in the database.
// The field names are also used in the url.
export enum Concept {
    Unknown = "Unknown",
    Taco = "Taco",
    PizzaHut = "PizzaHut",
    KFC = "KFC",
    IHOP = "IHOP",
    PANDA = "PANDA",
    Interfoods = "Interfoods",
    TacoBueno = "TacoBueno",
    HardRock = "HardRock",
    Zaxbys = "Zaxbys",
    Popeyes = "Popeyes",
    AuBonPain = "AuBonPain",
    Sonic = "Sonic",
    CottonPatch = "CottonPatch",
    Panera = "Panera",
    PapaJohns = "PapaJohns",
    HurricaneGrill = "HurricaneGrill",
    Portillos = "Portillos",
    Other = "Other",
    Perkins = "Perkins",
    Applebees = "Applebees",
    BurgerKing = "BurgerKing",
    CAMILLES = "CAMILLES",
    Dennys = "Dennys",
    DairyQueen = "DairyQueen",
    Hardees = "Hardees",
    LongJohnSilvers = "LongJohnSilvers",
    DEMOFULLSERVICE = "DEMOFULLSERVICE",
    Biscuitville = "Biscuitville",
    PremiumBrandsKFC = "PremiumBrandsKFC",
    FiveGuys = "FiveGuys",
    PremiumBrandsPH = "PremiumBrandsPH",
    BrooklynWaterBagel = "BrooklynWaterBagel",
    SlimChickens = "SlimChickens",
    LoadedCafe = "LoadedCafe",
    PapaMurphys = "PapaMurphys",
    WildWingCafe = "WildWingCafe",
    Capriottis = "Capriottis",
    Wendys = "Wendys",
    Wingstop = "Wingstop",
    TGIFridays = "TGIFridays",
    Subway = "Subway",
    Whataburger = "Whataburger",
    McDonalds = "McDonalds",
    Starbucks = "Starbucks",
    ChickfilA = "ChickfilA",
    Dunkin = "Dunkin",
    Dominos = "Dominos",
    ChipotleMexicanGrill = "ChipotleMexicanGrill",
    Arbys = "Arbys",
    LittleCaesars = "LittleCaesars",
    JackintheBox = "JackintheBox",
    OliveGarden = "OliveGarden",
    BuffaloWildWings = "BuffaloWildWings",
    ChilisGrillBar = "ChilisGrillBar",
    TexasRoadhouse = "TexasRoadhouse",
    OutbackSteakhouse = "OutbackSteakhouse",
    Culvers = "Culvers",
    JimmyJohnsGourmetSandwiches = "JimmyJohnsGourmetSandwiches",
    CrackerBarrel = "CrackerBarrel",
    RedLobster = "RedLobster",
    RaisingCanesChickenFingers = "RaisingCanesChickenFingers",
    JerseyMikesSubs = "JerseyMikesSubs",
    LongHornSteakhouse = "LongHornSteakhouse",
    TheCheesecakeFactory = "TheCheesecakeFactory",
    Bojangles = "Bojangles",
    CarlsJr = "CarlsJr",
    InNOutBurger = "InNOutBurger",
    RedRobin = "RedRobin",
    ElPolloLoco = "ElPolloLoco",
    DelTaco = "DelTaco",
    FirehouseSubs = "FirehouseSubs",
    QdobaMexicanEats = "QdobaMexicanEats",
    BJsRestaurantBrewhouse = "BJsRestaurantBrewhouse",
    SteaknShake = "SteaknShake",
    MarcosPizza = "MarcosPizza",
    ChurchsChicken = "ChurchsChicken",
    WaffleHouse = "WaffleHouse",
    KrispyKreme = "KrispyKreme",
    TropicalSmoothieCafe = "TropicalSmoothieCafe",
    GoldenCorral = "GoldenCorral",
    FreddysFrozenCustardSteakburgers = "FreddysFrozenCustardSteakburgers",
    McAlistersDeli = "McAlistersDeli",
    BaskinRobbins = "BaskinRobbins",
    Hooters = "Hooters",
    PFChangs = "PFChangs",
    DutchBrosCoffee = "DutchBrosCoffee",
    WhiteCastle = "WhiteCastle",
    MoesSouthwestGrill = "MoesSouthwestGrill",
    CheckersDriveInRestaurants = "CheckersDriveInRestaurants",
    CaptainDsSeafoodKitchen = "CaptainDsSeafoodKitchen",
    ShakeShack = "ShakeShack",
    CarrabbasItalianGrill = "CarrabbasItalianGrill",
    TimHortons = "TimHortons",
    CheddarsScratchKitchen = "CheddarsScratchKitchen",
    SmoothieKing = "SmoothieKing",
    NoodlesCompany = "NoodlesCompany",
    TheHabitBurgerGrill = "TheHabitBurgerGrill",
    MODPizza = "MODPizza",
    Jacks = "Jacks",
    HungryHowiesPizza = "HungryHowiesPizza",
    BobEvans = "BobEvans",
    RoundTablePizza = "RoundTablePizza",
    CharleysPhillySteaks = "CharleysPhillySteaks",
    RuthsChrisSteakHouse = "RuthsChrisSteakHouse",
    YardHouse = "YardHouse",
    EinsteinBrosBagels = "EinsteinBrosBagels",
    FirstWatch = "FirstWatch",
    CaliforniaPizzaKitchen = "CaliforniaPizzaKitchen",
    Jamba = "Jamba",
    JasonsDeli = "JasonsDeli",
    BonefishGrill = "BonefishGrill",
    TacoJohns = "TacoJohns",
    BostonMarket = "BostonMarket",
    PolloTropical = "PolloTropical",
    AuntieAnnes = "AuntieAnnes",
    ColdStoneCreamery = "ColdStoneCreamery",
    JetsPizza = "JetsPizza",
    MaggianosLittleItaly = "MaggianosLittleItaly",
    TheCapitalGrille = "TheCapitalGrille",
    MillersAleHouse = "MillersAleHouse",
    RubyTuesday = "RubyTuesday",
    PotbellySandwichShop = "PotbellySandwichShop",
    Chuys = "Chuys",
    MellowMushroom = "MellowMushroom",
    OCharleys = "OCharleys",
    RallysHamburgers = "RallysHamburgers",
    KrystalCompany = "KrystalCompany",
    TorchysTacos = "TorchysTacos",
    BlazePizza = "BlazePizza",
    CoopersHawkWineryRestaurants = "CoopersHawkWineryRestaurants",
    ZoesKitchen = "ZoesKitchen",
    CafeRiomexicanGrill = "CafeRiomexicanGrill",
    SaltgrassSteakHouse = "SaltgrassSteakHouse",
    Schlotzskys = "Schlotzskys",
    PeetsCoffee = "PeetsCoffee",
    DickeysBarbecuePit = "DickeysBarbecuePit",
    TheHoneyBakedHamCo = "TheHoneyBakedHamCo",
    TwinPeaks = "TwinPeaks",
    LogansRoadhouse = "LogansRoadhouse",
    Wienerschnitzel = "Wienerschnitzel",
    Cicis = "Cicis",
    FamousDaves = "FamousDaves",
    BraumsIceCreamDairyStores = "BraumsIceCreamDairyStores",
    TacoCabana = "TacoCabana",
    GodfathersPizza = "GodfathersPizza",
    BlackBearDiner = "BlackBearDiner",
    CaribouCoffee = "CaribouCoffee",
    Fazolis = "Fazolis",
    GoldenChick = "GoldenChick",
    OnTheBorderMexicanGrillCantina = "OnTheBorderMexicanGrillCantina",
    SarkuJapan = "SarkuJapan",
    AWAllAmericanFood = "AWAllAmericanFood",
    NinetyNineRestaurants = "NinetyNineRestaurants",
    PennStationEastCoastSubs = "PennStationEastCoastSubs",
    FlemingsPrimeSteakhouseWineBar = "FlemingsPrimeSteakhouseWineBar",
    BigBoyFrischsBigBoy = "BigBoyFrischsBigBoy",
    MountainMikesPizza = "MountainMikesPizza",
    DonatosPizza = "DonatosPizza",
    PeiWei = "PeiWei",
    PretAManger = "PretAManger",
    Sweetgreen = "Sweetgreen",
    PizzaRanch = "PizzaRanch",
    NewksEatery = "NewksEatery",
    LazyDogRestaurantBar = "LazyDogRestaurantBar",
    Smashburger = "Smashburger",
    FarmerBoys = "FarmerBoys",
    UncleJulios = "UncleJulios",
    TexasdeBrazilChurrascaria = "TexasdeBrazilChurrascaria",
    Sbarro = "Sbarro",
    Rubios = "Rubios",
    ChuckECheeses = "ChuckECheeses",
    Benihana = "Benihana",
    Giordanos = "Giordanos",
    ChickenSaladChick = "ChickenSaladChick",
    FogodeChao = "FogodeChao",
    DaveBusters = "DaveBusters",
    Seasons52 = "Seasons52",
    OldChicagoPizzaTaproom = "OldChicagoPizzaTaproom",
    FuzzysTacoShop = "FuzzysTacoShop",
    VillageInn = "VillageInn",
    BahamaBreezeIslandGrille = "BahamaBreezeIslandGrille",
    HuddleHouse = "HuddleHouse",
    LaRosasPizzeria = "LaRosasPizzeria",
    WhichWich = "WhichWich",
    FirebirdsWoodFiredGrill = "FirebirdsWoodFiredGrill",
    TrueFoodKitchen = "TrueFoodKitchen",
    CornerBakeryCafe = "CornerBakeryCafe",
    WalkOnsSportsBistreaux = "WalkOnsSportsBistreaux",
    UnoPizzeriaGrill = "UnoPizzeriaGrill",
    LeesFamousRecipeChicken = "LeesFamousRecipeChicken",
    SonnysBBQ = "SonnysBBQ",
    BillMillerBarBQ = "BillMillerBarBQ",
    PolloCampero = "PolloCampero",
    WaybackBurgers = "WaybackBurgers",
    OnoHawaiianBBQ = "OnoHawaiianBBQ",
    JAlexanders = "JAlexanders",
    MissionBBQ = "MissionBBQ",
    PappadeauxSeafoodKitchen = "PappadeauxSeafoodKitchen",
    BarLouie = "BarLouie",
    Cinnabon = "Cinnabon",
    CookOutRestaurant = "CookOutRestaurant",
    ParisBaguette = "ParisBaguette",
    LLHawaiianBarbecue = "LLHawaiianBarbecue",
    Jollibee = "Jollibee",
    WaBaGrill = "WaBaGrill",
    Bubbas33 = "Bubbas33",
    GreatHarvestBakeryCafe = "GreatHarvestBakeryCafe",
    MortonsTheSteakhouse = "MortonsTheSteakhouse",
    TijuanaFlats = "TijuanaFlats",
    TheOriginalPancakeHouse = "TheOriginalPancakeHouse",
    FiftyFourThStreetRestaurantDrafthouse = "FiftyFourThStreetRestaurantDrafthouse",
    MenchiesFrozenYogurt = "MenchiesFrozenYogurt",
    Friendlys = "Friendlys",
    BurgerFi = "BurgerFi",
    MastrosRestaurants = "MastrosRestaurants",
    SmokeyBonesBarFireGrill = "SmokeyBonesBarFireGrill",
    Topgolf = "Topgolf",
    Cava = "Cava",
    Roosters = "Roosters",
    BrueggersBagels = "BrueggersBagels",
    BucadiBeppo = "BucadiBeppo",
    DaylightDonuts = "DaylightDonuts",
    BeefOBradys = "BeefOBradys",
    BiggbyCoffee = "BiggbyCoffee",
    LaMadeleine = "LaMadeleine",
    TheCoffeeBeanTeaLeaf = "TheCoffeeBeanTeaLeaf",
    BrioTuscanGrille = "BrioTuscanGrille",
    Lubys = "Lubys",
    PDQ = "PDQ",
    IslandsFineBurgersDrinks = "IslandsFineBurgersDrinks",
    LedoPizza = "LedoPizza",
    MetroDiner = "MetroDiner",
    DuffysSportsGrill = "DuffysSportsGrill",
    RosatisPizza = "RosatisPizza",
    Runza = "Runza",
    MimisCafe = "MimisCafe",
    CostaVidaFreshMexicanGrill = "CostaVidaFreshMexicanGrill",
    WetzelsPretzels = "WetzelsPretzels",
    AndysFrozenCustard = "AndysFrozenCustard",
    Fuddruckers = "Fuddruckers",
    PieologyPizzeria = "PieologyPizzeria",
    BlackAngussteakhouse = "BlackAngussteakhouse",
    RomanosMacaroniGrill = "RomanosMacaroniGrill",
    LupeTortilla = "LupeTortilla",
    ScootersCoffee = "ScootersCoffee",
    CityBarbeque = "CityBarbeque",
    Shoneys = "Shoneys",
    EatnPark = "EatnPark",
    EddieVsPrimeSeafood = "EddieVsPrimeSeafood",
    AnthonysCoalFiredPizza = "AnthonysCoalFiredPizza",
    NoRTHItalia = "NoRTHItalia",
    DelFriscosDoubleEaglesteakHouse = "DelFriscosDoubleEaglesteakHouse",
    Yogurtland = "Yogurtland",
    GyuKaku = "GyuKaku",
    PappasitosCantina = "PappasitosCantina",
    SharisCafeandPies = "SharisCafeandPies",
    GreatAmericanCookies = "GreatAmericanCookies",
    NekterJuiceBar = "NekterJuiceBar",
    AmericanDeli = "AmericanDeli",
    Bonchon = "Bonchon",
    Chopt = "Chopt",
    ChickenExpress = "ChickenExpress",
    Sizzler = "Sizzler",
    MarlowsTavern = "MarlowsTavern",
    UnionJoints = "UnionJoints",
    ColdBeerCheeseburgers = "ColdBeerCheeseburgers",
    CHICKIESandPETES = "CHICKIESandPETES",
    FourGr8 = "FourGr8",
    AsianChickenDoneRight = "AsianChickenDoneRight",
    CoreLifeEatery = "CoreLifeEatery",
    AnthonysSeafoodAndRibs = "AnthonysSeafoodAndRibs",
    Quiznos = "Quiznos",
    TrinityBar = "TrinityBar",
    HopsGrapes = "HopsGrapes",
    CantinaLaredo = "CantinaLaredo",
    CowboyBar = "CowboyBar",
    CommonKitchen = "CommonKitchen",
    FourRiversSmokehouse = "FourRiversSmokehouse",
    TheCOOP = "TheCOOP",
    AnchorBar = "AnchorBar",
    FattysSeafood = "FattysSeafood",
    CrispGreen = "CrispGreen",
    Bistro = "Bistro",
    PrimantiBros = "PrimantiBros",
    Domoishi = "Domoishi",
    JirosGyros = "JirosGyros",
    RedBrickKitchen = "RedBrickKitchen",
    NonaBlueModernTavern = "NonaBlueModernTavern",
    HisshoSushi = "HisshoSushi",
    JoesKansasCityBarBQue = "JoesKansasCityBarBQue",
    LocalFavorites = "LocalFavorites",
    Mooyah = "Mooyah",
    Pokeworks = "Pokeworks",
    Bellagreen = "Bellagreen",
    FiammaPizza = "FiammaPizza",
    BigChicken = "BigChicken",
    MarksFeedStore = "MarksFeedStore",
    ZazaCuban = "ZazaCuban",
    PizzaPatron = "PizzaPatron",
    LionsChoice = "LionsChoice",
    MamboSeafood = "MamboSeafood",
    PapaCorazonsQuesadillas = "PapaCorazonsQuesadillas",
    CleanJuice = "CleanJuice",
    DaddysChickenShack = "DaddysChickenShack",
    BakerybyPerkins = "BakerybyPerkins",
    MeltDown = "MeltDown",
    BurgerDen = "BurgerDen",
    ElBolilloBakery = "ElBolilloBakery",
    CommonBond = "CommonBond",
    WingNut = "WingNut",
    StonersPizzaJoint = "StonersPizzaJoint",
    BossChicken = "BossChicken",
    PeterPiperPizza = "PeterPiperPizza",
    FishCityGrillAndHalfShellsSeafood = "FishCityGrillAndHalfShellsSeafood",
    BeefARoo = "BeefARoo",
    EighteenthStreetPizza = "EighteenthStreetPizza",
    GetLoaded = "GetLoaded",
    RiverhorseOnMain = "RiverhorseOnMain",
    Evos = "Evos",
    VeganKitchen = "VeganKitchen",
    TavernInTheSquare = "TavernInTheSquare",
    IndigoCafe = "IndigoCafe",
    BlackwallBarnAndLodge = "BlackwallBarnAndLodge",
    Titan = "Titan",
    QuakerSteakAndLube = "QuakerSteakAndLube",
    MyBurger = "MyBurger",
    WowBao = "WowBao",
    AppsAllAround = "AppsAllAround",
    ChickenConviction = "ChickenConviction",
    CoppinsRestBar = "CoppinsRestBar",
    KnowledgeBarSR = "KnowledgeBarSR",
    FirstDistCafe = "FirstDistCafe",
    CholitaLinda = "CholitaLinda",
    HuHotMongolianGrill = "HuHotMongolianGrill",
    SmithfieldsChickenNBarBQ = "SmithfieldsChickenNBarBQ",
    WoodnTap = "WoodnTap",
    TheShakeBar = "TheShakeBar",
    SnSCatering = "SnSCatering",
    WingStreet = "WingStreet",
    ChampysChicken = "ChampysChicken",
    TheGreeneTurtle = "TheGreeneTurtle",
    MiguelsJR = "MiguelsJR",
    LuckysCraftFoodandDrink = "LuckysCraftFoodandDrink",
    HatCreekBurgerCo = "HatCreekBurgerCo",
    RockingChair = "RockingChair",
    Piccadilly = "Piccadilly",
    KandW = "KandW",
    TexsChickenandBurgers = "TexsChickenandBurgers"
}

export interface IThresholdValue {
    thresholdType: ThresholdType;
    minValue?: number | null;
    maxValue?: number | null;
}

export interface IClientConceptMetricThreshold {
    clientConcept_PK: number;
    metricDefinitionId: number;
    description: string | null;
    metricDefinition: IModuleMetricDefinition;
    thresholdValues: {[xtd: string]: IThresholdValue};
}

export interface IIdealWeeklySalesPerEmployeeThresholdForHomeOffice {
    clientConcept_PK: number;
    idealWeeklySalesPerEmployeeThreshold: number | null;
}

export interface ILocation {
    LocationId: string;
    locationPk: number;
    locationNumber: string;
    locationName: string;
    parentGroupName: string;
}

export interface ILocationThreshold {
    locationInfo: ILocation;
    thresholdValues: IThresholdValue;
}

export interface ILocationMetricThreshold {
    metricDefinitionId: number;
    description: string;
    metricDefinition: IModuleMetricDefinition;
    locationThresholds: ILocationThreshold[];
}

export interface IColumnMetadata {
    key: string;
    label: string;
    dataType?: DataType;
    decimalPlaces?: number;
    unit?: string;
    hasFilter?: boolean;
    tooltipText?: string;
    isToggleable?: boolean;
    isToggledToVisible?: boolean;
}

export interface IDataTableData {
    [key: string]: string | boolean | number | undefined | null;
}

// This interface is meant to allow a custom column to be passed into a <DataTable> which will
// replace a column of data that would have been displayed in that table.
// -columnToReplace: the string value of the header column that will be replaced
// -getCustomColumnMetaData: function to return custom Table.Cell
export interface ICustomColumnsTransformation {
    [columnToReplace: string]: {
        getCustomColumnMetaData: CustomColumnMetadataFunctionType;
    };
}

export interface ICustomColumnMetaData {
    element: JSX.Element;
    sortValue: number | string | boolean | undefined | null;
}

export type CustomColumnMetadataFunctionType = (
    dataTableRow: IDataTableData,
    column: IColumnMetadata,
    isMobileScreen: boolean
) => ICustomColumnMetaData;

export enum DataStatus {
    Undefined = "Undefined",
    Loaded = "Loaded",
    Loading = "Loading",
    Failed = "Failed",
    Forbidden = "Forbidden"
}
export enum PageRoleAccess {
    All = "All",
    Admin = "Admin",
    HomeOffice = "HomeOffice"
}

export enum Product {
    Coach = "Coach",
    Detect = "Detect",
    Delivery = "Delivery",
    Recovery = "Recovery",
    DeliveryOperations = "DeliveryOperations"
}

//Used for Error Handling
//Based on https://datatracker.ietf.org/doc/html/rfc7807
export interface IProblemDetails {
    detail: string;
    instance: string;
    status: number;
    title: string;
    type: string;
    name: string;
}
