import * as React from "react";
import {Container, Message} from "semantic-ui-react";
import {IRootGroupInvalidLocations} from "../api/configurationModels";

interface IProps {
    groupIdSetupInvalidLocations: IRootGroupInvalidLocations;
}

const GroupTreeValidationError: React.FunctionComponent<IProps> = (props) => {
    return (
        <Container style={{paddingTop: "20px"}}>
            <Message negative>
                <Message.Header>Invalid Group Tree</Message.Header>
                <p>
                    Locations appears multiple times in the Dashboard Root Node:{" "}
                    <b>{props.groupIdSetupInvalidLocations.groupName}</b>{" "}
                </p>
                <p>Admins can resolve this by modifying the group tree setup.</p>
                <p>The locations that appear multiple times are:</p>
                <ul>
                    {props.groupIdSetupInvalidLocations.invalidLocations.map(
                        (invalidLocation) => {
                            return (
                                <li key={invalidLocation.locationNumber}>
                                    <b>{invalidLocation.locationNumber}</b> (
                                    {invalidLocation.count})
                                </li>
                            );
                        }
                    )}
                </ul>
            </Message>
        </Container>
    );
};

export default GroupTreeValidationError;
