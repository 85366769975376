import * as React from "react";
import {Container, Message} from "semantic-ui-react";

interface IProps {
    bannerMessage: string;
}

const BannerMessage: React.FunctionComponent<IProps> = (props) => {
    return (
        <Container style={{paddingTop: "20px"}}>
            <Message
                warning
                style={{textAlign: "center", overflowWrap: "break-word"}}
            >
                <p>{props.bannerMessage} </p>
            </Message>
        </Container>
    );
};

export default BannerMessage;
