import {Action} from "redux";
import store from "state/store";
import {ErrorMessageContent} from "util/errorUtil";
import {actionCreator, AppThunkAction} from "../../../state/actions";
import hj from "../../../tracking/hotjar";
import {deleteCookie} from "../../../util/cookieUtil";
import {
    IClientConcept,
    IUserInfo,
    IUserSettings,
    IBusinessUnit,
    IClientConceptMetricDefinition,
    IPeriodStartDate,
    IClientConceptPage,
    IProblemDetails
} from "../../application/api/applicationModels";
import authApi from "../api/authAPI";
// this is one way to define actions/action creators --------------------------

export const AUTH_SUCCESS = "auth/SUCCESS";
export const AUTH_FAILURE = "auth/FAILURE";
export const AUTHORIZATION_FAILURE = "authorization/FAILURE";
export const AUTH_ERROR = "auth/ERROR";
export const AUTH_SIGNOUT = "auth/SIGNOUT";
export const AUTH_CONCEPTS = "auth/CONCEPTS";

interface IAuthSuccessAction extends Action<typeof AUTH_SUCCESS> {
    clientConcepts: IClientConcept[];
    clientConceptsForCoachDetect: IClientConcept[];
    clientConceptsForCoachHomeOffice: IClientConcept[];
    clientConceptsForCoachDeliveryPage: IClientConcept[];
    clientConceptsForCoachDifferentialPay: IClientConcept[];
    clientConceptsForCoachRecovery: IClientConcept[];
    clientConceptsForDeliveryOperations: IClientConcept[];
    clientTreeHierarchy: IBusinessUnit[];
    clientConceptMetricDefinitions: IClientConceptMetricDefinition[];
    clientConceptPages: IClientConceptPage[];
    userInfo: IUserInfo;
    isClientOptedOutOfTaskEmailing: boolean;
    openTaskCount: number;
    userSettings: IUserSettings;
    errorMessage: string;
    periodStartDates: IPeriodStartDate[];
    bannerMessage: string;
}

interface IAuthenticationFailureAction extends Action<typeof AUTH_FAILURE> {
    error: string;
}

interface IAuthorizationFailureAction extends Action<typeof AUTHORIZATION_FAILURE> {
    error: string;
}

interface IAuthErrorAction extends Action<typeof AUTH_ERROR> {
    error: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAuthSignOutAction extends Action<typeof AUTH_SIGNOUT> {}

export const authSuccess = actionCreator<IAuthSuccessAction>(AUTH_SUCCESS);

export const authenticationFailure =
    actionCreator<IAuthenticationFailureAction>(AUTH_FAILURE);

export const authorizationFailure =
    actionCreator<IAuthorizationFailureAction>(AUTHORIZATION_FAILURE);

export const authError = actionCreator<IAuthErrorAction>(AUTH_ERROR);

export const signOut = actionCreator<IAuthSignOutAction>(AUTH_SIGNOUT);
export const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) return error.message;
    return String(error);
};

export type AuthAction =
    | IAuthSuccessAction
    | IAuthenticationFailureAction
    | IAuthorizationFailureAction
    | IAuthErrorAction
    | IAuthSignOutAction;

// ----------------------------------------------------------------------------

export const actionCreators = {
    authenticate(loginToken: string): AppThunkAction<AuthAction> {
        return (dispatch) => {
            authApi
                .authenticate({loginToken})
                .then((data) => {
                    dispatch(
                        authSuccess({
                            clientConcepts: data.clientConcepts,
                            clientConceptsForCoachDetect:
                                data.clientConceptsForCoachDetect,
                            clientConceptsForCoachHomeOffice:
                                data.clientConceptsForCoachHomeOffice,
                            clientConceptsForCoachDeliveryPage:
                                data.clientConceptsForCoachDeliveryPage,
                            clientConceptsForCoachDifferentialPay:
                                data.clientConceptsForCoachDifferentialPay,
                            clientConceptsForCoachRecovery:
                                data.clientConceptsForCoachRecovery,
                            clientConceptsForDeliveryOperations:
                                data.clientConceptsForDeliveryOperations,
                            clientTreeHierarchy: data.clientTreeHierarchy,
                            clientConceptMetricDefinitions:
                                data.clientConceptMetricDefinitions,
                            clientConceptPages: data.clientConceptPages,
                            userInfo: data.userInfo,
                            isClientOptedOutOfTaskEmailing:
                                data.isClientOptedOutOfTaskEmailing,
                            openTaskCount: data.openTaskCount,
                            userSettings: data.userSettings,
                            errorMessage: data.errorMessage,
                            periodStartDates: data.periodStartDates,
                            bannerMessage: data.bannerMessage
                        })
                    );
                    //hotjar tagging
                    hj("tagRecording", [
                        "ClientCode- " + data.userInfo.clientCode,
                        "UserName- " + data.userInfo.userName
                    ]);
                })
                .catch((res: IProblemDetails) => {
                    // Any 401 errors are already dispatched in the apiUtils.postData function via validateResponse()
                    if (res.status === 401) {
                        return;
                    }
                    // Handling other errors here
                    if (res.status === 403) {
                        store.dispatch(
                            authorizationFailure({
                                error:
                                    res.detail ||
                                    res.title ||
                                    ErrorMessageContent.NoDashboardAccess
                            })
                        );
                    } else {
                        //catch-all case for other types of errors
                        store.dispatch(
                            authError({
                                error:
                                    res.detail ||
                                    res.title ||
                                    ErrorMessageContent.SomethingWentWrong
                            })
                        );
                    }
                });
        };
    },

    authenticateFailed(error: unknown): AppThunkAction<AuthAction> {
        return (dispatch) => {
            dispatch(authenticationFailure({error: getErrorMessage(error)}));
        };
    },

    signOut(): AppThunkAction<AuthAction> {
        return (dispatch) => {
            dispatch(signOut({}));
            deleteCookie("loginToken");
        };
    }
};
